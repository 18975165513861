<template>
  <div >
    <!-- 操作选择区域 -->
    <div style="margin-left: 0px">
    <van-button type="default" style="margin-left: 2px" title="可将图放大2倍" @click="zoomIn">{{zoomed?'原图':'放大'}}</van-button>
    <!-- 单点单色代替-->
      <van-button style="margin-left: 2px" @click="numb('')" title="选好本颜色后，按下鼠标左键可在图中涂画">{{0}} </van-button>
    <van-button style="margin-left: 2px" v-for="num in 4"  :style="{ backgroundColor: getColorFromNumber(num)}" title="选好本颜色后，按下鼠标左键可在图中涂画" @click="numb(num)">{{num}}</van-button>
    <!-- 全色代替-->
      <van-button style="margin-left: 2px"  plain @click="showPopup = true" title="可以将图中某一颜色一次性全部代换" >全色代换</van-button>
<van-popover v-model="showPopup" position="bottom">
     <div style="padding:20px;">
      <van-button :style="{background:ecolo}" title="选择图中已有颜色"  @click="showPopover = true;showPopover1 = false">{{oric?oric:'原色'}}</van-button>
    <van-popover v-model="showPopover" position="bottom" trigger="click" :actions="colorMap" @select="onSelect">
    </van-popover>
         <van-button :style="{background:ecoln}" title="改成你想要的颜色"  @click="showPopover1 = true;showPopover = false">{{newc?newc:'替色'}}</van-button>
    <van-popover v-model="showPopover1" position="bottom" trigger="click" :actions="colorMap" @select="onSelect1">
    </van-popover>
    <van-button type="info" plain @click="replaceColor">替换颜色</van-button>
       <van-button type="warning" plain @click="showPopup = false;showPopover = false;showPopover1 = false">关闭</van-button>
       </div>
</van-popover>
      <!-- 保存-->
      <van-button type="warning"  style="margin-left: 10px"  @click="saveChanges" title="将改动保存">保存</van-button>
    </div>
  <div :class="zoomed ? 'grid-container1' : 'grid-container'"
       @mousedown.prevent="setMouseDown"
  @mouseup.prevent="mouseDown = false"
  @mouseleave.prevent="mouseDown = false">
    <div
      v-for="(number, index) in colorsArray"
      :key="index"
      :class="zoomed ? 'grid-item1' : 'grid-item'"
      :style="{ backgroundColor: getColorFromNumber(number)}"
    @mouseover="applyColor(index)">{{number}}</div>
  </div>
<!--     @click="updateColor(index)"-->
    </div>
</template>
<script>
import axios from 'axios'
import {Button,Popover,} from "vant";

export default {
  components:{
    [Button.name]:Button, [Popover.name]:Popover,
  },
  data() {
    return {
      showPopup:false,
     showPopover: false,
      showPopover1: false,
    url:process.env.VUE_APP_URL,
    ws:process.env.VUE_APP_WS,
    mouseDown: false,
    colorData1:'',
    pixitem:this.$route.params.id,
    colnum:'',
    // colorData: JSON.parse(JSON.stringify(colorData)), // 创建colorData的可变拷贝
    zoomed: false,
    colorsArray: new Array(64 * 64).fill(''),// 初始化一个64x64的数组，填充默认颜色
      originalColor: '',  // 原有颜色选择
      newColor: '',  // 改后颜色选择
      oric:'',
      newc:'',
      ecolo:'',
      ecoln:'',
      colorMap: [{text: '粉色',id:1,col:'pink'}, {text: '黄色',id:2,col:'yellow'},
        {text: '浅蓝',id:3,col:'lightblue'}, {text: '浅绿',id:4,col:'lightgreen'}],
    };
  },
  mounted() {
    window.addEventListener('mouseup', this.globalMouseUp);
    this.loadColorData();
    this.$nextTick(() => {
    console.log('colorsArray!!:', this.colorsArray);
  });
  },
  beforeDestroy() {
   window.removeEventListener('mouseup', this.globalMouseUp);
},
  methods: {
    onSelect(act){
      this.oric=act.text
      this.ecolo=act.col
      this.originalColor=act.id
    },
      onSelect1(act){
      this.newc=act.text
      this.ecoln=act.col
      this.newColor=act.id
    },
    setMouseDown() {
    console.log('Mouse down event triggered');
    this.mouseDown = true;
  },
    globalMouseUp() {
      console.log('Mouse up event triggered');
    this.mouseDown = false;
  },
    numb(num){
      console.log('numb',num)
      this.colnum = num
    },
    applyColor(index){
      console.log('applyColor',index,this.mouseDown,this.colnum)
      if(this.mouseDown && this.colnum !==null){
        this.updateColor(index, this.colnum); // 调用updateColor方法并传入当前选择的颜色
      }
    },
    updateColor(index,newColor) {
      console.log('mouse',index,newColor)
    this.$set(this.colorsArray, index, newColor);
    const x = index % 64;
    const y = Math.floor(index / 64);
    // 构建colorData中的键
    const key = `${x},${y}`;
    this.$set(this.colorData1, key, newColor);

    // console.log('col1',this.colorData1)
    const colorDataString = JSON.stringify(this.colorData1)
    localStorage.setItem('pix64',colorDataString)
    // this.colorData[key] = newColor;
  },
    zoomIn(){
    this.zoomed = !this.zoomed;
    },
    loadColorData() {
       // this.colorData1 = JSON.parse(this.pixitem['pix64']);
       this.colorData1 = this.pixitem['pix64'];
      console.log('开始加载颜色数据...',this.colorData1);
        for (const [key, value] of Object.entries(this.colorData1)) {
          const [x, y] = key.split(',').map(Number);
          const colorIndex = y * 64 + x;
          // this.colorsArray[colorIndex] = value;
          this.$set(this.colorsArray, colorIndex, value);
        }
        console.log('颜色数据加载完毕');
    },
    getColorFromNumber(number) {
      const colorMap = {
        1: 'pink',//'brown'
        2: 'yellow',//'orange',//
        3: 'lightblue',//'black',
        4: 'lightgreen',//'grey',
      };
      return colorMap[number] || 'white'; // 如果找不到编号，返回默认颜色
    },
    replaceColor() {
      for (let i = 0; i < this.colorsArray.length; i++) {
        if (this.colorsArray[i] === parseInt(this.originalColor)) {
          this.updateColor(i, parseInt(this.newColor));
        }
      }
      for (const [key, value] of Object.entries(this.colorData1)) {
        if (value === parseInt(this.originalColor)) {
          this.colorData1[key] = parseInt(this.newColor);
        }
      }
      const colorDataString = JSON.stringify(this.colorData1);
      localStorage.setItem('pix64', colorDataString);
    },
        saveChanges() {
      const colorDataProcessed = JSON.stringify(this.colorData1); // 格式化json
          console.log("colorDataProcessed",colorDataProcessed)
      const saveData = {
        id: this.pixitem.id,  // 使用传递的id
        pix64: colorDataProcessed,
      };
      axios.put(this.url + 'pix/updatepix/', saveData)  // 使用实际的保存端点
        .then(response => {
          console.log('保存成功:', response.data);
          alert('颜色数据保存成功');
        })
        .catch(error => {
          console.error('保存失败:', error);
          alert('颜色数据保存失败');
        });
    }
  }
};
</script>

<style scoped>
.grid-container {
  cursor: grab; /* 手指指针 */
  /*overflow: hidden; !* 隐藏溢出内容 *!*/
  display: grid;
  grid-template-columns: repeat(64, 10px); /* 格子宽度 */
  grid-template-rows: repeat(64, 10px); /* 格子高度 */
  gap: 1px; /* 格子间隙 */
  background-color: #f0f0f0; /* 临时背景颜色 */
}
.grid-container1 {
  cursor: grab; /* 手指指针 */
  /*overflow: hidden; !* 隐藏溢出内容 *!*/
  display: grid;
  grid-template-columns: repeat(64, 20px); /* 格子宽度 */
  grid-template-rows: repeat(64, 20px); /* 格子高度 */
  gap: 1px; /* 格子间隙 */
  background-color: #f0f0f0; /* 临时背景颜色 */
}
.grid-item {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #efeeee;
  font-size: 4px /* 根据格子大小调整字体大小 */;
  border: 1px solid #ddd;
}
.grid-item1 {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #efeeee;
  font-size: 8px /* 根据格子大小调整字体大小 */;
  border: 1px solid #ddd;
}
</style>
