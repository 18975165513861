<template>
  <div>
     <van-pagination v-model="page" :page-count="pagecount" mode="simple" @change="pagechange"/>
    <van-grid   :column-num="4" :border="false" >
  <van-grid-item v-for="item in history" :key="item.id" @click="todetail(item)">
<!--      <pix64rend v-if="c64" :colorData="item.pix64"></pix64rend>-->
      <pixzone128rend :colorData="item.pix128"></pixzone128rend>
  </van-grid-item>
    </van-grid>
    </div>
</template>

<script>
import {
  Field, CellGroup, Button, List, Cell, Row, Toast, Form,
  Tab, Tabs, Grid, GridItem, Image, Icon, Overlay, Pagination, Col, Dialog, DropdownItem, DropdownMenu, Popover
} from 'vant'
import pixzone128rend from "@/components/Pixzone128rend";
import axios from "axios";
export default {
  components:{
    pixzone128rend,
    [CellGroup.name]:CellGroup,[Form.name]:Form,[Field.name]:Field,[Button.name]:Button,[Grid.name]:Grid,[GridItem.name]:GridItem,
    [Overlay.name]:Overlay,[Row.name]:Row,[Col.name]:Col,[List.name]:List,[Cell.name]:Cell,[Row.name]:Row,
    [Tab.name]:Tab,[Tabs.name]:Tabs,[Image.name]:Image,[Icon.name]:Icon,[Pagination.name]:Pagination,[Popover.name]:Popover,
    [Dialog.Component.name]:Dialog.Component,[DropdownMenu.name]:DropdownMenu,[DropdownItem.name]:DropdownItem,

  },
  data() {
    return {
    url:process.env.VUE_APP_URL,
    ws:process.env.VUE_APP_WS,
    history:[],
      c64:true,
      pixname:'',
      page:1,
      size:8,
      pagecount:''
    };
  },
  mounted() {
    this.fhistory()
  },
  methods: {
    pagechange(){
      this.fhistory()
      console.log(this.page)
    },
    todetail(item){
      console.log(item)
     this.$router.push({
         name:'pixtab',
         params:{id:item}
       })
    },
    fhistory() {
       let localuserid = JSON.parse(localStorage.getItem('userinfo'))['userid']
       axios.get(this.url+'pix/pixhistory?'+'&belong='+localuserid+'&page='+this.page+'&size='+this.size).then(
      // axios.get(this.url + 'pix/pixhistory',).then(
          res => {
            console.log('res.data', res.data)
            this.history = res.data.items
            this.pagecount = res.data.pages
          })
    },
  }
};
</script>

<style scoped>
</style>
