import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import '@vant/touch-emulator'
import VueNativeSock from 'vue-native-websocket'

Vue.use(VueNativeSock,process.env.VUE_APP_WS,{
    connectManually:true,
    // store:store,
    format:'json',
    // reconnection:true,
    // reconnectionAttempts:5,
    // reconnectionDelay:3000
},
)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
    if (localStorage.getItem('userinfo') || to.path === "/login") {
        next()
    } else {
        next({
            path: "/login"
        })
    }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
