<template>
  <div >
<!--    {{url+this.$route.params.id.piximg}}-->
      <van-image :src="url+this.$route.params.id.piximg" />
    </div>
</template>

<script>
import {Image} from "vant";
export default {
  components:{
    [Image.name]:Image
  },
  data() {
    return {

      pixitem:require(this.url+this.$route.params.id.piximg),
    url:process.env.VUE_APP_URL,
    ws:process.env.VUE_APP_WS,
    };
  },
   created() {
    this.url = process.env.VUE_APP_URL
       console.log('url1', this.url);
     },
  mounted() {
  },
  methods: {}
};
</script>

<style scoped>
.grid-container {
  cursor: grab; /* 手指指针 */
  /*overflow: hidden; !* 隐藏溢出内容 *!*/
  display: grid;
  grid-template-columns: repeat(128, 1px); /* 格子宽度 */
  grid-template-rows: repeat(128, 1px); /* 格子高度 */
  gap: 0.5px; /* 格子间隙 */
  /*background-color: #f0f0f0; !* 临时背景颜色 *!*/
}
.grid-item {
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #efeeee;
  /*font-size: 2px !* 根据格子大小调整字体大小 *!;*/
  /*border: 1px solid #ddd;*/
}
</style>
