export const WebSockMixin = {
    methods: {
            websoc() {
      let user = JSON.parse(localStorage.getItem('userinfo'));
      if (!this.$socket || this.$socket.readyState === 3) {  // 检查socket是否存在且已经关闭
        this.$connect(process.env.VUE_APP_WS+'ws/'+user['username']+'/'+user['userid']+'/',
          {
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 3000
          })
        this.$socket.onmessage = this.onMessageReceived;
        this.$socket.onclose = () => {
          console.log('ws断开了，WebSocket disconnect, try to reconnect..');
          setTimeout(() => this.websoc(), 5000);
        }
      } else {
        console.log('WebSocket is still alive, no need to reconnect..');
      }
    },
            onMessageReceived(data) {
      const message = data.data;
      console.log('ws后端来的',message)
      // this.$store.commit('act',[{'text':message}])
    },
        }
    }