<template>
  <div class="home">
     <van-row>
      <van-col span="4">
        <van-list >
          <van-cell   >
            <router-link  :to="{name:'pixzone'}" >Pix空间</router-link>
            </van-cell>
           <van-cell >
             <router-link  :to="{name:'pixmake'}" >Pix制作</router-link>
            </van-cell>
        </van-list>
    </van-col>
      <van-col span="20">
        <router-view></router-view>

         </van-col>
      </van-row>
  </div>
</template>

<script>
import Pixzone from "@/components/Pixzone";
import Pixmake from "@/components/Pixmake";
import {List, Cell, Row, Col, Icon, Image,} from 'vant'
export default {
  name: 'Pix',
  components: {
    Pixzone,Pixmake,
    [List.name]:List,[Cell.name]:Cell,[Row.name]:Row,[Col.name]:Col,[Icon.name]:Icon,[Image.name]:Image,
  },
  data(){
    return{url:process.env.VUE_APP_URL,}
  },
  methods:{

  }
}
</script>
<style scoped>
a {
  /*font-weight: bold;*/
  color: #2c3e50;
}

a.router-link-exact-active {
  color: #42b983;
}
</style>
