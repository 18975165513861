<template>
  <div id="app">
<!--    {{fastmessage}}-->
    <nav>
<!--      <router-link to="/ws1" v-if="this.$store.state.log">ws1测试 |</router-link>-->
<!--      <router-link to="/fy" v-if="this.$store.state.log">百度翻译</router-link>-->
<!--      <router-link to="/" v-if="this.$store.state.log"> GPT通用 |</router-link>-->
      <router-link to="/" v-if="this.$store.state.log"> GPT通用 |</router-link>
      <router-link to="/gpt4" v-if="this.$store.state.log"> GPT4 |</router-link>
      <router-link to="/mj" v-if="this.$store.state.log"> 文生图 |</router-link>
      <router-link to="/pix" v-if="this.$store.state.log"> Pix |</router-link>
      <router-link to="/" > {{ fastmessage}}</router-link>
      <div style="float: right;" v-if="this.$store.state.log">
<!--        <van-popover v-model="showPopover" trigger="click"   :actions="this.$store.state.actions"-->
<!--                     @select="onSelect">-->
<!--         </van-popover>-->
<!--         <van-button size="small" plain type="danger" v-if="this.$store.state.actions.length>0" @click="showPopover=true">通知</van-button>-->
        <router-link  to="/login" @click.native="logout" >
          <van-button size="small" plain type="primary">退出</van-button>
          <van-button size="small" plain type="warning">{{this.$store.state.userinfo.username}}</van-button>
          </router-link>
        </div>
    </nav>
    <router-view/>
  </div>
</template>

<script>
import {Notify,Button, Popover, Collapse, CollapseItem, Badge, Icon} from "vant";
export default {
  components: {
    [Popover.name]:Popover,[Button.name]:Button,[Collapse.name]:Collapse,[CollapseItem.name]:CollapseItem,
  },
  data(){
    return{
      showPopover: false,
      show:false,
      ws:process.env.VUE_APP_WS,
      fastmessage:""
    }
  },
    created() {
    window.addEventListener('storage',res=>{
      console.log('res',res)
      if(res.key==='userinfo' || res.newValue==null ){
        this.$store.commit('log',false)
        this.$disconnect()
        this.$router.push('/login')
      }
    })
    if(localStorage.getItem('userinfo')) {
      let user = localStorage.getItem('userinfo')
      console.log('Applocal',user,JSON.parse(user)['username'])
      this.$disconnect()
      this.$connect(this.ws + 'ws/' + JSON.parse(user)['username'] + '/' + JSON.parse(user)['userid'] + '/',
          {
            reconnection: true, reconnectionAttempts: 5,
            reconnectionDelay: 3000
          })
      this.$socket.onmessage = this.onMessageReceived;
       this.$store.commit('login',JSON.parse(localStorage.getItem('userinfo')))
      console.log('ok连接', this.$socket)
    }
  },
  // watch:{
  //   '$store.state.actions':function (newActions){
  //     if(newActions[0].text==='ok'){
  //       Notify({ type: 'warning', message:newActions[0].text+',发送成功,等待回复'  })
  //     }
  //     if(newActions[0].text==='mj'){
  //       if (this.$route.path === '/mj/'||this.$route.path === '/mj' ) {
  //          this.$router.go(0)
  //       }
  //       // Notify({ type: 'warning', message:newActions[0].text+'图片制作成功，请前往查看'  })
  //     }
  //     if(newActions[0].text==='did'){
  //       Notify({ type: 'warning', message:newActions[0].text+'视频制作成功，请前往查看'  })
  //     }
  //     if(newActions[0].text==='leo'){
  //       Notify({ type: 'warning', message:newActions[0].text+'视频制作成功，请前往查看'  })
  //     }
  //      console.log('变化了',newActions[0].text,newActions[0].time,)
  //   }
  // },
  methods:{
    onMessageReceived(data) {
      const message = data.data;
      this.fastmessage=message.slice(-3)
      console.log('console晚上后端来的',message)
      if(message==='ok'){Notify({ type: 'warning', message:'ok提交成功'  })}
      if(message==='mj') {
        console.log('mj收到')
              Notify({ type: 'warning', message:'MJ制作成功b'})
        if (this.$route.path === '/mj/' || this.$route.path === '/mj') {
          this.$router.go(0)
        }
      }
      // this.$store.commit('act',[{'text':message,'time':new Date().toLocaleString()}])
    },
    // changeactions(){
    //    this.$store.commit('act',[{'text':'mj','time':new Date().toLocaleString()}])
    // },
    onSelect(action) {
      console.log(action,action.text)
      if(action.text==='mj'){
        if (this.$route.path === '/mj/'||this.$route.path === '/mj' ) {
          this.$router.go(0)
        } else {
         this.$router.push({name:'mjmake'})
        }
        this.$store.commit('act',[])
        // this.$store.commit('login',JSON.parse(localStorage.getItem('userinfo')))
      }
    },
    logout(){
      localStorage.removeItem('userinfo')
      this.$store.commit('log',false)
      this.$disconnect()
    },
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('userinfo'))
    if(userInfo===null) return
    if(userInfo.username){
      this.$store.commit('log',true)
    }

  }

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
#footer{
position:absolute;
      left:50%;
      bottom:0;
      text-align :center;
}
.child {
  width: 42px;
  height: 32px;
  background: #f2f3f5;
  border-radius: 4px;
}
</style>
