<template>
  <div >
  <div class="grid-container">
    <div
      v-for="(number, index) in colorsArray"
      :key="index"
      class="grid-item"
      :style="{ backgroundColor: getColorFromNumber(number)}"></div>
  </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  props:{
    colorData:String,
  },
  data() {
    return {
    colorsArray: new Array(128 * 128).fill('') // 初始化一个64x64的数组，填充默认颜色
    };
  },
  mounted() {
    this.loadColorData();
  },
  beforeDestroy() {
   window.removeEventListener('mouseup', this.globalMouseUp);
},
  methods: {
    loadColorData() {
      const colorData1 = this.colorData;
  // const colorData1 = JSON.parse(this.colorData);
        for (const [key, value] of Object.entries(colorData1)) {
          const [x, y] = key.split(',').map(Number);
          const colorIndex = y * 128 + x;
          // this.colorsArray[colorIndex] = value;
          this.$set(this.colorsArray, colorIndex, value);
        }
        console.log('颜色数据加载完毕');
    },
    getColorFromNumber(number) {
      const colorMap = {
        1: 'pink',
        2: 'yellow',
        3: 'lightblue',
        4: 'lightgreen',
      };
      return colorMap[number] || 'white'; // 如果找不到编号，返回默认颜色
    }
  }
};
</script>

<style scoped>
.grid-container {
  cursor: grab; /* 手指指针 */
  /*overflow: hidden; !* 隐藏溢出内容 *!*/
  display: grid;
  grid-template-columns: repeat(128, 1px); /* 格子宽度 */
  grid-template-rows: repeat(128, 1px); /* 格子高度 */
  gap: 0.5px; /* 格子间隙 */
  /*background-color: #f0f0f0; !* 临时背景颜色 *!*/
}
.grid-item {
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #efeeee;
  /*font-size: 2px !* 根据格子大小调整字体大小 *!;*/
  /*border: 1px solid #ddd;*/
}
</style>
