import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    diacontent:{},
    act1:false,
    actions:[],
    style:'',
    userinfo:{},
    userid:'',
    talk:0,
    log:'',
    souimg:''
  },
  getters: {
  },
  mutations: {
    souimg(state,v){
      state.souimg = v
    },
     todia(state,v){
      state.diacontent = v
    },
     act1(state,v){
      state.act1 = v
    },
    act(state,v){
      state.actions = v
    },
      log(state,v){
      state.log = v
    },
    login(state,v){
      state.userinfo = v
    },
    userid(state,v){
      state.userid = v
    },
    style(state,v){
      state.style = v
    },
     talk(state,v){
      state.talk = v
    }
  },
  actions: {
  },
  modules: {
  }
})

