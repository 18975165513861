<template>
<van-tabs v-model="activeName">
  <van-tab title="Pix128" name="a">
    <Pixdetail128></Pixdetail128>
  </van-tab>
  <van-tab title="Pix64" name="b">
    <Pixdetail64></Pixdetail64>
  </van-tab>
  <van-tab title="原图" name="c">
    <Pixdetailimg></Pixdetailimg>
  </van-tab>
</van-tabs>
</template>

<script>
import {Tab,Tabs} from "vant";
import Pixdetail128 from "@/components/pixdetail128";
import Pixdetail64 from "@/components/pixdetail64";
import Pixdetailimg from "@/components/Pixdetailimg";

export default {
  name: "PixTab",
  components:{
     Pixdetail128,Pixdetail64,Pixdetailimg,
    [Tabs.name]:Tabs,[Tab.name]:Tab
  },
  data(){
    return{
      activeName: 'a',
    }
  }
}
</script>

<style scoped>

</style>