import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import mjmake from "@/components/Mjmake";
import mjdetail from "@/components/Mjdetail";
import tunerlist from "@/components/Tunerlist";
import mjoperate from "@/components/Mjoperate";
import mjdescribe from "@/components/Mjdescribe";
import Mj from "@/views/Mj";
import Pix from "@/views/Pix";
import pixzone from "@/components/Pixzone";
import pixmake from "@/components/Pixmake";
import pixdetail128 from "@/components/pixdetail128";
import pixdetail64 from "@/components/pixdetail64";
import pixtab from "@/components/PixTab";
import pixdetailimg from "@/components/Pixdetailimg";

Vue.use(VueRouter)

const routes = [
    {
    path: '/ws1',
    name: 'ws1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ws1.vue')
  },
    {
       path: '/login',
    name: 'login',
   component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/',
    name: 'gpt1',
    component: () => import(/* webpackChunkName: "gpt1" */ '../views/Gpt1.vue')
  },
      {
    path: '/gpt4',
    name: 'gpt4',
    component: () => import(/* webpackChunkName: "gpt4" */ '../views/Gpt4.vue')
  },
    {
    path: '/mj',
    name: 'mj',
    component:Mj,
      children:[
        {path:'/',
          name:'mjmake',
          component:mjmake},
          {path:'/tunerlist',
           name:'tunerlist',
          component:tunerlist},
         {path:'/mjdetail/:id',
           name:'mjdetail',
          component:mjdetail},
          {path:'/mjoperate',
           name:'mjoperate',
          component:mjoperate},
          {path:'/mjdescribe',
           name:'mjdescribe',
          component:mjdescribe},
      ]
  },
    {
    path: '/pix',
    name: 'pix',
    component:Pix,
      children:[
        {path:'/',
          name:'pixzone',
          component:pixzone},
          {path:'/pixtab',
           name:'pixtab',
          component:pixtab},
          {path:'/pixdetail128/:id',
           name:'pixdetail128',
          component:pixdetail128},
           {path:'/pixdetail64/:id',
           name:'pixdetail64',
          component:pixdetail64},
          {path:'/pixdetailimg/:id',
           name:'pixdetailimg',
          component:pixdetailimg},
          {path:'/pixmake',
           name:'pixmake',
          component:pixmake},
      ]
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
