<template>
  <div class="home" style="display: flex;height: 100vh;">
    <!-- 左半页 -->
    <div style="flex: 1; display: flex; justify-content: center; align-items: center; border: 1px solid #ccc;">
      <div class="image-selection">
        <input ref="fileInput" type="file" style="display: none;" @change="onFileSelected">
        <div class="image-box" @click="uploadFromComputer">
          <div v-if="!previewImage" class="placeholder-text">选择图片</div>
          <img v-else :src="previewImage" class="uploaded-image" />
        </div>
      </div>
    </div>

    <!-- 中间的上传按钮 -->
    <div style="display: flex; align-items: center;padding: 0 20px;">
      <van-button type="primary" @click="uploadFile" :disabled="!previewImage">生成PIX</van-button>
    </div>

    <!-- 右半页 -->
    <div style="flex: 1; display: flex; justify-content: center; align-items: center; border: 1px solid #ccc;">
<!--            <div @click="todetail(item)">{{ responseMessage }}</div>-->
      <div class="image-box" @click="todetail(item)">
      <pixmake128rend v-if="item!==''" :colorData="item.pix128"></pixmake128rend>
        </div>
<!--      <div>{{ responseMessage }}</div>-->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import pixmake128rend from "@/components/Pixmake128rend";
import {
  Button,
  Uploader,
} from 'vant'
import {WebSockMixin} from "@/mixin/websocMixin";
export default {
  name: 'HomeView',
  mixins:[WebSockMixin],
  components: {
    pixmake128rend, [Button.name]:Button,[Uploader.name]:Uploader,
  },
  data(){
    return {
      url:process.env.VUE_APP_URL,
      ws:process.env.VUE_APP_WS,
      value: '',
      previewImage: null,
      file: null,
      item:'',
      // photoPopover: false,
      responseMessage:''
    }
  },
  created() {
    this.url = process.env.VUE_APP_URL
       console.log('url1', this.url);
     },
  methods: {
    todetail(item){
      this.$router.push({
         name:'pixtab',
         params:{id:item}
       })
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      this.file = file;
      this.previewImage = URL.createObjectURL(file);
      // 重置文件输入元素的值
        this.$refs.fileInput.value = '';
    },
    uploadFromComputer() {
      this.$refs.fileInput.click();
    },
    uploadFile(event) {
      if (!this.file) return;
      let localuserid = JSON.parse(localStorage.getItem('userinfo'))['userid']
      let formData = new FormData();
      formData.append('userid',localuserid)
      formData.append('file', this.file);
      axios.post(this.url + 'pix/uploadfile/', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then(
          res => {
            console.log(res.data)
            // this.imagePath = res.data.path;
            localStorage.setItem('imagePath', res.data.pix128)
            this.responseMessage = res.data.message || '成功，点击前往编辑';
            this.item = res.data
              // this.previewImage = null;
              // this.file = null;
            // this.imagePath = `http://127.0.0.1:8000${res.data.path}`;
          })
      .catch(err => {
          console.log(err);
          this.responseMessage = '生成PIX失败';
        });
      // 处理上传逻辑
    },
  },
  mounted() {
  }

}
</script>
<style scoped>
.image-box {
 width: 100%;  /* Use percentage to make it responsive */
  aspect-ratio: 1;  /* Maintain 1:1 aspect ratio */
  /*border: 2px dashed #ccc;*/
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 200px;  /* Ensure a minimum height */
  background-color: #f9f9f9;  /* Optional: adds a subtle background color */
}

.placeholder-text {
  color: #aaa;
  font-size: 16px;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
}

.upload-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
</style>
