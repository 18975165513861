<template>
  <div class="home">
      <van-tabs v-model="active" type="card" color="#efeeee" title-active-color="#42b983" title-inactive-color="#131313">
        <van-tab title="maid" >
    <van-grid   :column-num="8" >
  <van-grid-item v-for="item in maid" :key="item.id" >
    <van-image :src="url+'mj/media/maid_'+item+'.webp'"  @click="locast(item)" />
    <van-button type="info" size="large"  plain @click="locast(item)">{{item}}</van-button>
  </van-grid-item>
</van-grid>
  </van-tab>
  <van-tab title="streetfashionboy" >
    <van-grid   :column-num="8" >
  <van-grid-item v-for="item in streetfashionboy" :key="item.id"  >
    <van-image :src="url+'mj/media/streetfashionboy_'+item+'.webp'"  @click="locast(item)" />
      <van-button type="info" size="large" plain  @click="locast(item)">{{item}}</van-button>
  </van-grid-item>
</van-grid>
  </van-tab>
        <van-tab title="monkonsea">
    <van-grid   :column-num="8" >
  <van-grid-item v-for="item in monkonsea" :key="item.id" >
    <van-image :src="url+'mj/media/monkonsea_'+item+'.webp'"  @click="locast(item)" />
    <van-button type="info" size="large" plain @click="locast(item)">{{item}}</van-button>
  </van-grid-item>
</van-grid>
  </van-tab>
</van-tabs>

  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import {Field,CellGroup,Button,List,Cell,Row,Tab,Tabs,Grid,GridItem,Image,Icon} from 'vant'
export default {
  name: 'Tunerlist',
  components: {
    HelloWorld,
    [CellGroup.name]:CellGroup,[Field.name]:Field,[Button.name]:Button,[Grid.name]:Grid,[GridItem.name]:GridItem,
    [List.name]:List,[Cell.name]:Cell,[Row.name]:Row,[Tab.name]:Tab,[Tabs.name]:Tabs,[Image.name]:Image,[Icon.name]:Icon
  },
  data(){
    return{
      url:process.env.VUE_APP_URL,
      history:'',
      active:0,
      monkonsea:['3My2wkrJ','6hpmWeXR','cwyGZ1gb','k18GeKOz','CKAEn5Hx','ZekC8imH','1TqGwxj1B','2YRUpOV15',
        '5HsY91WZN','8XMDOSMYf','h7zP0vSUn','qUwQ24oPJ','PnUnAXGE7','1iKLqFDeqb','2uaU3mj5Rl','3UhtcAjJbx',
        '7sxT4Eljv1','bIRAwkndtB','mnEQ8wrYq3','zaBWtyxGlN','158ZHk8LXb9','1HvR0lf34Yn','3fr0gSZJTsr','56xAbWizgQ7',
        '9Kj1ZzyDIij','fjCLKJt7Qrl','teV79DflaNV','JWSkr6YNzf1','1pILmDOjrykL','2dQD0ufvOJE3','4fai76lvKGVh','6FxT2DF6SeR9'],
      streetfashionboy:['4tZloGLd', '6FxT3l6pKBaD', 'PoBOTQ2XB', 'fjCMsaM0cKP', 'kIzZ7783', 'h8hgjofdR', 'ZVLV0EGb','teV7R4ydx7p',
        'JWSl8yhFVyv', '2dQD1bGOH5Xx', '1iLsRYvAJF', '3UiaDTc5v1', '2ubBuFbsaP', 'ddZZRnzF', '9Kj2H0Rw4BN', '1HvRHMxVrhR',
        '7syAvXdFOv', '1590oLrEjuD', '2ZzlIHhkz', 'zaCDURq2Fh', 'Ds1Xfs11', '56xATnBrD9B', '3fr0YkiCfLV', 'mnFxzPkkJx',
        '6YQFOBhl', '4fai7NMOD3eL', '8Yu57L9hJ', '1U87PpFl5', 'qVehkWL9d', 'bIShXDfzN5', '1pILnlfCjUEf', '5IaprUjjh'],
      maid:['4clx48xj','6HcRu33r','cWmbwPlL','kqWaMyU9','Dao8UTN7','ZE86G6sh','1TQu1577b','2ZhHUmJ6F',
        '5HSLDzL5n','8YcrjqB3P','h7ZCv3GZX','qUWDwCcVj','Pokb5vuJH','1iLbeab2vL','2ubjQQQTWV','3UhT04Rxh7',
      '7syiS8T7AB','bIS0jOV1zb','mnFfW0ZMvD','zaCmh35urn','159077DjLgJ','1HvRq8JAT3X','3fr0GGuhHy1','56xABJN74VH',
      '9Kj2pn3bwnT','fjCMawXFEwV','teV7zqJSYTv','JWSkQUtlnkB','1pILn3BNZmql','2dQD0U30mxJD','4fai7w90iv0R','6FxT33sBq2WJ']
    }
  },
  methods:{
    locast(item){
      this.$store.commit("style",item)
      console.log(item)
    }
    },
}
</script>
