<template>
  <div class="home">
    <van-image :src="url+$store.state.diacontent.mjimg.replace(/.png/gi,'_thumbnail.png')" />
    <div style="margin-bottom: 10px;margin-left: 1rem">
      <van-row>
        <van-col span="10">
<!--           <van-button type="primary"  size="small" plain v-if="!$store.state.diacontent.phototype.includes('Image')" style="float: left;" @click="upscale(1,$store.state.diacontent.mjreid,$store.state.diacontent.photo)">U1高清</van-button>-->
          <van-button type="primary"  size="small" plain v-if="!$store.state.diacontent.phototype.includes('Image')" style="float: left;" @click="upscale(1,$store.state.diacontent)">U1高清</van-button>
    <van-button  type="primary"  size="small"  plain  v-if="!$store.state.diacontent.phototype.includes('Image')" style="float: left;margin-left:0.5px; " @click="upscale(2,$store.state.diacontent)">U2高清</van-button>
      <van-button type="primary"  size="small"  plain v-if="!$store.state.diacontent.phototype.includes('Image')"  style="float: left;margin-left:0.5px" @click="upscale(3,$store.state.diacontent)">U3高清</van-button>
    <van-button  type="primary"  size="small"  plain  v-if="!$store.state.diacontent.phototype.includes('Image')" style="float: left;margin-left:0.5px" @click="upscale(4,$store.state.diacontent)">U4高清</van-button>

        </van-col>
        <van-col span="10">
          <van-button type="warning"  size="small"  v-if="!$store.state.diacontent.phototype.includes('Image')"  style="float: left;margin-left:0.5px" @click="variation(1,$store.state.diacontent)">V1变化</van-button>
    <van-button  type="warning"  size="small"  v-if="!$store.state.diacontent.phototype.includes('Image')"  style="float: left;margin-left:0.5px" @click="variation(2,$store.state.diacontent)">V2变化</van-button>
      <van-button type="warning"  size="small" v-if="!$store.state.diacontent.phototype.includes('Image')"   style="float: left;margin-left:0.5px" @click="variation(3,$store.state.diacontent)">V3变化</van-button>
    <van-button  type="warning"  size="small" v-if="!$store.state.diacontent.phototype.includes('Image')"   style="float: left;margin-left:0.5px" @click="variation(4,$store.state.diacontent)">V4变化</van-button>
        </van-col>
        <van-col span="4">
           <van-button  type="info"  size="small"  plain v-if="!$store.state.diacontent.phototype.includes('Image')"  style="float: left" @click="reset($store.state.diacontent)" >重来</van-button>
              <van-button  type="warning"  size="small"  plain  style="float: left;margin-left:1px" @click="mjlossphoto($store.state.diacontent)">失图补充</van-button>
<!--           <van-button  type="warning"  size="small"  plain  style="float: left" @click="mjdetail($store.state.diacontent)"><van-icon name="play-circle-o" size="20" /></van-button>-->
        </van-col>
      </van-row>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import {Notify,Field, CellGroup, Button, List, Cell, Row, Col, Tab, Tabs, Grid, GridItem, Image, Icon} from 'vant'
import {WebSockMixin} from "@/mixin/websocMixin";
export default {
  name: 'Mjoperate',
  mixins:[WebSockMixin],
  components: {
    [CellGroup.name]:CellGroup,[Field.name]:Field,[Button.name]:Button,[Grid.name]:Grid,[GridItem.name]:GridItem,
    [List.name]:List,[Cell.name]:Cell,[Row.name]:Row,[Col.name]:Col,[Tab.name]:Tab,[Tabs.name]:Tabs,[Image.name]:Image,
    [Icon.name]:Icon,
  },
  data(){
    return{
      url:process.env.VUE_APP_URL,
      value:'',
      show:false,
      // MjItem:{}
    }
  },
  methods: {
        upscale(index,item){
          this.websoc()
          console.log(index,item.mjreid,item.photo,'sock状态',this.$socket.readyState,this.$socket.OPEN)
      let localuserid = JSON.parse(localStorage.getItem('userinfo'))['userid']
      let msg_hash = item.photo.slice(-40,-4)
      console.log(index,item.mjreid,msg_hash)
          let updata={index:index,msg_id:item.mjreid,msg_hash:msg_hash}
          axios.post(this.url+'mj/upscale/',updata
       ).then(
         res=>{
           console.log(res.data)
          if(res.status===200){
             Notify({ type: 'primary', message: '提交成功，等待通知' })
           }
         }
    )
    },
    variation(index,item){
          this.websoc()
      let msg_hash = item.photo.slice(-40,-4)
      console.log(index,item.mjreid,msg_hash)
      let vardata={index:index,msg_id:item.mjreid,msg_hash:msg_hash}
       axios.post(this.url+'mj/variation/',vardata
       ).then(
         res=>{
           console.log(res.data)
          if(res.status===200){
             Notify({ type: 'primary', message: '提交成功，等待通知' })
           }
         }
    )
    },
    mjlossphoto(item){
       console.log('los',item,item.id,item.mjattid)
       let lossdata = {mjattid:item.mjattid,photo:item.photo,mjimg:item.mjimg,
                       belong: item.belong_id}
          this.websoc()
      axios.post(this.url+'mj/mjlossphoto/',lossdata).then(
          res=>{
            console.log(res.data)
          }
      )
    },
    reset(item){
          this.websoc()
      let msg_hash = item.photo.slice(-40,-4)
      console.log(item.mjreid,msg_hash)
      let resetdata={index:0,msg_id:item.mjreid,msg_hash:msg_hash}
       axios.post(this.url+'mj/reset/',resetdata
       ).then(
         res=>{
           console.log(res.data)
           if(res.status===200){
              Notify({ type: 'primary', message: '提交成功，等待通知' })
           }
         }
    )
    },
    mjdetail(item){
       this.$router.push({
         name:'mjdetail',
         params:{id:item}
       })
    },

  }
}
</script>
