<template>
  <div class="container">
    <van-uploader v-model="fileList[0]" :after-read="handleImageUpload" />
  </div>
</template>

<script>
import axios from "axios";
import {Uploader} from "vant";

export default {
  name: "Mjdescribe.vue",
   components: {
    [Uploader.name]:Uploader
  },
  data(){
    return{
      url:process.env.VUE_APP_URL,
      value:'',
      answer1:'',
    fileList:[]
    }
   },
  methods: {
    handleImageUpload(file) {
      // 处理文件上传逻辑
      const formData = new FormData();
      formData.append('image', file.file);
      // 发送POST请求到Django后台接口
      axios.post(this.url+'upload_image/', formData).then((response) => {
        console.log(response.data);
        // 根据需要对返回结果进行处理
      }).catch((error) => {
        console.error(error);
        // 错误处理
      });
    }
  }
}
</script>

<style scoped>

</style>