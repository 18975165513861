<template>
  <div class="home">
    {{'--cref '+this.$route.params.id.mjimg.split(':')[0]+'s://'+this.$route.params.id.mjimg.split('//')[1]}}
  <van-image :src="url+this.$route.params.id.mjimg" />
  </div>
</template>
<script>

import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import {Field,CellGroup,Button,List,Cell,Row,Col,Tab,Tabs,Grid,GridItem,Image} from 'vant'
export default {
  name: 'Mjdetail',
  components: {
    HelloWorld,
    [CellGroup.name]:CellGroup,[Field.name]:Field,[Button.name]:Button,[Grid.name]:Grid,[GridItem.name]:GridItem,
    [List.name]:List,[Cell.name]:Cell,[Row.name]:Row,[Col.name]:Col,[Tab.name]:Tab,[Tabs.name]:Tabs,[Image.name]:Image
  },
  data(){
    return{
      url:process.env.VUE_APP_URL,
      value:'人生，不过一杯茶！ 满也好，少也好，争个什么,浓也好，淡也好，自有味道。 急也好，缓也好，那又如何？ 暖也好，冷也好，相视一笑',
      didid:'tlk_5-wbTmlqZJzQ_h2iXC2lr',
      didid1:'tlk_cr7yGOYlor4e-al4SsEjh',
      answer1:'',
      history:'',
      active:1,
    }
  },
  methods: {
    didgen() {
      let data = new FormData()
      data.append('words', this.value)
      data.append('photo', this.$route.params.id.photo)
      axios.post(this.url + 'didgen/', data).then(
          res => {
            console.log(res.data)
            this.didid = res.data.a
          }
      )
    },
    didget() {
      let data = new FormData()
      data.append('words', this.didid)
      axios.post(this.url + 'didget/', data).then(
          res => {
            console.log(res.data)
            this.his = res.data.a
            this.value = ''
          }
      )
    },
  }
}
</script>
