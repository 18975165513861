<template>
  <div class="home">
    <van-cell-group>
        <van-form >
          <div class="put-on-head">
           <van-popover v-model="showPopover" trigger="click"  :actions="actions" @select="onSelect">
         </van-popover>
           <div style="margin-left: 0px;display: flex;align-items: center">
           <van-button size="small" type="primary"  @click="showPopover=true">{{lenguage.display}}</van-button>
           </div>
    <van-field v-model="raw" clearable name="pattern" autosize rows="1" type="textarea" placeholder="在此输入提示词"
               @keydown.shift.enter="validator">
    <template #button>
       <van-button size="small" type="primary" :disabled="buttondisable" @click="validator" >
        Mj生成
      </van-button>
    </template>
    </van-field>
             </div>
          </van-form>
  </van-cell-group>
            <van-row>
              <van-col span="6">
        <van-field v-model="whvalue"   clearable label="宽高比" placeholder="1:1"></van-field></van-col>
              <van-col span="18">
              </van-col>
              </van-row>
    <div>
     <van-pagination v-model="page" :page-count="pagecount" mode="simple" @change="pagechange"/>
    <van-grid   :column-num="5" :border="false" >
  <van-grid-item v-for="item in history" :key="item.id" >
     <van-button  size="large" plain @mouseover.native="toast(item)">{{item.rawprompt.slice(0,15)}}</van-button>
     <van-image :src="url+item.imageUrl" @click="dialogshow(item)"  />
<!--    @error="handlemgError(item)"-->
    <van-button size="large">
    <van-row v-if="item.phototype.includes('Image')">
      <van-col span="8">
        <div style="margin-left: 1rem">
          <van-icon name="play-circle-o"  @click=mjdetail(item)>大图</van-icon>
        </div>
      </van-col>
       <van-col span="8">
        <div style="margin-left: 1rem">
          <van-icon name="play-circle-o"   @click=samplesrcchange(item)>取样</van-icon>
        </div>
      </van-col>
       <van-col span="8">
         <div style="margin-left: 1rem">
         <van-icon  name="brush-o" @click=dialogshow(item)>操作</van-icon>
           </div>
      </van-col>
    </van-row>
      <van-row v-if="!item.phototype.includes('Image')">
      <van-col span="12">
        <div style="margin-left: 1rem">
          <van-icon name="play-circle-o"  @click=mjdetail(item)>大图</van-icon>
        </div>
      </van-col>
       <van-col span="12">
         <div style="margin-left: 1rem">
         <van-icon  name="brush-o" @click=dialogshow(item)>操作</van-icon>
           </div>
      </van-col>
    </van-row>
      </van-button>
<van-dialog v-model="diashow"  width="40%" closeOnClickOverlay :show-confirm-button="false">
    <mjoperate></mjoperate>
</van-dialog>
  </van-grid-item>
</van-grid>
</div>
  </div>
</template>

<script>
import axios from 'axios'
import {
  Field, CellGroup, Button, List, Cell, Row, Toast, Form,
  Tab, Tabs, Grid, GridItem, Image, Icon, Overlay, Pagination, Col, Dialog, DropdownItem, DropdownMenu, Popover
} from 'vant'
import tunerlist from "@/components/Tunerlist";
import mjoperate from "@/components/Mjoperate";
import { WebSockMixin } from "@/mixin/websocMixin.js";
import md5 from "crypto-js/md5";
export default {
  name: 'Mjmake',
  mixins:[WebSockMixin],
  components: {
    tunerlist,mjoperate,
    [CellGroup.name]:CellGroup,[Form.name]:Form,[Field.name]:Field,[Button.name]:Button,[Grid.name]:Grid,[GridItem.name]:GridItem,
    [Overlay.name]:Overlay,[Row.name]:Row,[Col.name]:Col,[List.name]:List,[Cell.name]:Cell,[Row.name]:Row,
    [Tab.name]:Tab,[Tabs.name]:Tabs,[Image.name]:Image,[Icon.name]:Icon,[Pagination.name]:Pagination,[Popover.name]:Popover,
    [Dialog.Component.name]:Dialog.Component,[DropdownMenu.name]:DropdownMenu,[DropdownItem.name]:DropdownItem,
  },
  data(){
    return{
      samplesrc:'',
      whvalue:"1:1",
      novalue:'',
      url:process.env.VUE_APP_URL,
      ws:process.env.VUE_APP_WS,
      value:'',
      answer1:'',
      history:'',
      active:1,
      upscalere:'',
      show:false,
      diashow:false,
      buttondisable:false,
      hisprompt:'',
      page:1,
      size:10,
      pagecount:'',
      appid:'20240110001936581',
      key:'BVY60BY80vmMsICgvxrV',
      raw:'',
      prompt:'',
      gendata:'',
      pattern:/--/,
      // pattern:/^[a-zA-Z ,.!:0-9_()，。]+$/,
      downing:require("../assets/downing.png"),
      showPopover:false,
      actions:[{display:'英',text:'输入英文',lenguage:'en'},{display:'中',text: '输入中文',lenguage:'zh'}],
      lenguage:{display:'英',text:'输入英文',lenguage:'en'},
    }
  },
  methods:{
    samplesrcchange(item){
       this.$store.commit("souimg",item.mjimg)
      // this.samplesrc = item.mjimg
    },
    onSelect(version){
      console.log(version)
      this.lenguage=version
    },
    // onFailed(errorInfo){
    //   console.log('错误',errorInfo.errors[0].message)
    // },
     sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  //   async handlemgError(item) {
  // console.log('图片取不到', event.target)
  // try {
  //   // item.imageUrl=require("../assets/downing.png")
  //   const newUrl = await this.mjlossphoto(item)
  //   // console.log('newUrl',newUrl)
  //   await this.sleep(2000);
  //   item.imageUrl = newUrl.replace(/.png/gi,'_thumbnail.png')+`?timestamp=${Date.now()}`;
  //   // console.log(item.imageUrl)
  // }
  //  catch(error){
  //   console.log(error)
  //  }
  //   },
    toast(item){
      Toast({message:item.rawprompt+'\n'+item.prompt,closeOnClickOverlay:true,position: 'top',})
    },
    dialogshow(item){
      console.log('mjattid',item.mjattid)
      this.$store.commit('todia',item)
      this.diashow=true
    },
    baidutrans(){
      let Enmessage = encodeURIComponent(this.raw)
      let data = {raw:this.raw,enmessage:Enmessage}
        axios.post(this.url+'mj/baidutrans/',data).then(res=> {
            if(res.status===200){
               console.log(res.data)
              this.prompt = res.data.trans
              Dialog.confirm({
         title:'提示',
         message:'你的提示词为:'+'\n'+this.raw+'\n'+'你的英文提示词为'+'\n'+this.prompt+'\n',
         confirmButtonColor:'#44e77e',
              }).then(()=>{
         this.generate()
       }
       ).catch(()=>{console.log('取消')})
            }
            console.log(res.data)
          }
      )
    },
    validator(){
      if(this.lenguage.lenguage==='zh'){
        if((this.raw.includes('--'))||(!this.raw)){Dialog.alert({title:'提示',message:'不能为空 也不能有 --，\n 宽高比等参数请在下方填写',})}
        else if(this.raw===localStorage.getItem('mjrevalue')){
       Dialog.confirm({
         title:'提示',
         message:'内容与上条相同',
         confirmButtonColor:'#f91b48',
       }).then(()=>{
           this.baidutrans()
        }).catch(()=>{console.log('取消')})}
         else{
          this.baidutrans()}
      }
      if(this.lenguage.lenguage==='en'){
        if(!this.raw){Dialog.alert({title:'提示',message:'不能为空',})}
        else if(this.raw===localStorage.getItem('mjrevalue')){
       Dialog.confirm({
         title:'提示',
         message:'内容与上条相同',
         confirmButtonColor:'#f91b48',
       }).then(()=>{
           this.prompt = this.raw
        this.generate()
        }).catch(()=>{console.log('取消')})}
        else{
          this.prompt = this.raw
          this.generate()}
      }
       console.log('field')
    },
    pagechange(){
      this.mjhistory()
      console.log(this.page)
    },
    clearstyle(){
      this.$store.commit('style','')
    },
    operate(item){
      this.$router.push({
         name:'mjoperate',
         params:{id:item}
       })
    },
    mjdetail(item){
       this.$router.push({
         name:'mjdetail',
         params:{id:item}
       })
    },
    // if(this.$store.state.style !==''){
    //     data.append('prompt',used+this.prompt+mid+this.$store.state.style +' --ar '+this.whvalue)
    //     data.append('raw',this.raw+mid+this.$store.state.style)
    //   }else{
    //     data.append('prompt',used+this.prompt+' --ar '+this.whvalue)
    //     data.append('raw',this.raw)
    //   }
    generate(){
      this.websoc()
      let localuserid = JSON.parse(localStorage.getItem('userinfo'))['userid']
      let mjsalt = Date.now();
      console.log('Now',mjsalt)
      let used = '(N='+localuserid+'N='+mjsalt+'N=CxL5VivEQ),';
      // let souimg1 = this.$store.state.souimg.split('://')
      // let url1 = "https://63b5-183-193-177-170.ngrok-free.app/"+"media/"
      let souimg2 = ' --cref '+ this.url +this.$store.state.souimg
      // let souimg2 = ' --cref '+souimg1[0]+'s://'+souimg1[1]
      console.log(souimg2)
      // let data = new FormData()
      if(this.$store.state.souimg !==''){
        let prompt = used+this.prompt+souimg2 +' --ar '+this.whvalue
        let raw = this.raw+souimg2
        this.gendata={type:'Generate',userid:localuserid,mjsalt:mjsalt,prompt:prompt,raw:raw}
      }else{
        let prompt = used+this.prompt+' --ar '+this.whvalue
        let raw = this.raw
         this.gendata={type:'Generate',userid:localuserid,mjsalt:mjsalt,prompt:prompt,raw:raw}
      }
      localStorage.setItem('mjrevalue',this.raw)
      axios.post(this.url+'mj/generate/',this.gendata
       ).then(
         res=>{
           if(res.status===200){
             console.log(res.data)
             // this.buttondisable=true
               // setTimeout(this.reload,60000)
           }
         }
    )
    },
    mjlossphoto(item){
      console.log(item)
      let lossdata = {mjattid:item.mjattid,photo:item.photo,mjimg:item.mjimg,
                       belong: item.belong_id}
          this.websoc()
      return new Promise((resolve,reject)=>{
      axios.post(this.url+'mj/mjlossphoto/',lossdata).then(
          res=>{
            console.log(res.data)
            const newUrl = this.url+'media/mj/'+res.data['filename']
              console.log('newUrl',newUrl)
              resolve(newUrl)
          }
      )
      })
    },
    reload(){
      // this.$router.push({path:'/mj1',query:{'id':1}})
      history.go(0)
    },
    mjhistory(){
      let localuserid = JSON.parse(localStorage.getItem('userinfo'))['userid']
       // axios.get(this.url+'mjhistory/?'+'prompt='+this.hisprompt+'&belong='+localuserid+'&page='+this.page).then(
            axios.get(this.url+'mj/mjhistory?'+'&belong='+localuserid+'&page='+this.page+'&size='+this.size).then(
            res=>{
              console.log(res.data)
              // this.history =res.data.results
              this.history =res.data.items
              this.history.forEach(item => {item.imageUrl = item.mjimg.replace(/.png/gi,'_thumbnail.png');
               });
              this.pagecount = res.data.pages
              // this.pagecount = Math.ceil((res.data.count)/10)
            }
        )
    },
  },
  mounted() {
    this.mjhistory()
  },
}
</script>
<style>
.van-hairline--surround {
  border: 0 solid #e3e3e3;
  box-sizing: border-box;
}
.put-on-head{
  /*margin: 15px;*/
  /*border:2px solid;*/
  display: flex;
  /*overflow: hidden;*/

}
</style>
